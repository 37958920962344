<template>
    <div class="rm-wrapper" :class="{'loading-body':loading}">
        <div class="loading-mask">
            <van-loading color="#333" />
        </div>
        <div class="rm-tab-nav flex-row flex-column-center">
            <div class="rm-tab-nav__item" v-for="item of tabNav" :key="item.id" :class="{'is-active':tabIndex==item.id}"
                 @click="toggleTab(item)">
                <div class="rm-tab-nav__inner flex-row flex-center">
                    <div>
                        <div :class="['iconfont', item.icon]"></div>
                        <div class="rm-tab-nav__label">{{ item.title }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rm-tab-body">
            <div class="rm-tab-panel" v-show="tabIndex==13">
                <div class="rm-lamp-list">
                    <div class="rm-lamp-item" v-for="(item, index) of lampList" :key="index">
                        <div>
                            <div class="rm-lamp-switch" :class="{'is-active':item.value=='1'}" @click="sendCmd(item,5)">
                                <div class="rm-lamp-switch__inner">
                                    <div>
                                        <span v-if="item.loading" class="iconfont"><van-loading></van-loading></span>
                                        <span v-else class="iconfont icon-dengshi"
                                              :class="[item.value=='0'?'icon-dengshi':'icon-deng']"></span>
                                    </div>
                                </div>
                                <div class="rm-lamp-switch__label">{{ item.alias }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="rm-tab-panel" v-show="tabIndex==8">
                <div class="flex-row flex-center" style="padding-top:10px;">
                    <div class="rm-kt-btn" @click="onAirConditioningControl('powerSwitch', '开')">
                        <div class="rm-kt-btn__icon"><span class="iconfont icon-power"></span></div>
                        <div class="rm-kt-btn__text">开关</div>
                    </div>
                </div>
                <div class="flex-item flex-row flex-column-center">
                    <div class="flex-item">
                        <div class="rm-kt-temperature flex-row flex-column-center">
                            <div class="rm-kt-temperature-increase">
                                <span @click="onAirConditioningControl('temperature', -1)"></span>
                            </div>
                            <div class="rm-kt-temperature-number">{{airConditioner.temperature}}<span>℃</span></div>
                            <div class="rm-kt-temperature-decrease">
                                <span @click="onAirConditioningControl('temperature', 1)"></span>
                            </div>
                        </div>
                        <div class="rm-kt-temperature-tip">室内温度</div>
                    </div>
                </div>
                <div class="rm-fengsu">
                    <div class="rm-fengsu-label">
                        <span class="iconfont icon-feng"></span>
                        <span>风速</span>
                    </div>
                    <div class="flex-row rm-fengsu-bar">
                        <div class="flex-item">
                            <div @click="onAirConditioningControl('windSpeed', 2)">
                                <div class="rm-fengsu-bar__dotted"><span></span></div>
                                <div class="rm-fengsu-bar__label">低级</div>
                            </div>
                        </div>
                        <div class="flex-item is-active">
                            <div @click="onAirConditioningControl('windSpeed', 3)">
                                <div class="rm-fengsu-bar__dotted"><span></span></div>
                                <div class="rm-fengsu-bar__label">中级</div>
                            </div>
                        </div>
                        <div class="flex-item">
                            <div @click="onAirConditioningControl('windSpeed', 4)">
                                <div class="rm-fengsu-bar__dotted"><span></span></div>
                                <div class="rm-fengsu-bar__label">高级</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rm-kt-btns flex-row flex-wrap">
                    <div class="rm-kt-btns__item">
                        <div class="rm-kt-btn" @click="onAirConditioningControl('mode', 3)">
                            <div class="rm-kt-btn__icon"><span class="iconfont icon-chushi"></span></div>
                            <div class="rm-kt-btn__text">除湿</div>
                        </div>
                    </div>
                    <div class="rm-kt-btns__item">
                        <div class="rm-kt-btn" @click="onAirConditioningControl('mode', 5)">
                            <div class="rm-kt-btn__icon"><span class="iconfont icon-taiyang"></span></div>
                            <div class="rm-kt-btn__text">制热</div>
                        </div>
                    </div>
                    <div class="rm-kt-btns__item">
                        <div class="rm-kt-btn" @click="onAirConditioningControl('mode', 2)">
                            <div class="rm-kt-btn__icon"><span class="iconfont icon-zhileng"></span></div>
                            <div class="rm-kt-btn__text">制冷</div>
                        </div>
                    </div>
                    <div class="rm-kt-btns__item">
                        <div class="rm-kt-btn" @click="onAirConditioningControl('mode', 4)">
                            <div class="rm-kt-btn__icon"><span class="iconfont icon-songfeng"></span></div>
                            <div class="rm-kt-btn__text">送风</div>
                        </div>
                    </div>
                    <div class="rm-kt-btns__item">
                        <div class="rm-kt-btn" @click="onAirConditioningControl('windDirection', 1)">
                            <div class="rm-kt-btn__icon"><span class="iconfont icon-feng"></span></div>
                            <div class="rm-kt-btn__text">自动风</div>
                        </div>
                    </div>
                    <div class="rm-kt-btns__item">
                        <div class="rm-kt-btn" @click="onAirConditioningControl('manualWindDirection', 1)">
                            <div class="rm-kt-btn__icon"><span class="iconfont icon-shangxiasaofeng"></span></div>
                            <div class="rm-kt-btn__text">上下扫风</div>
                        </div>
                    </div>
                    <div class="rm-kt-btns__item">
                        <div class="rm-kt-btn" @click="onAirConditioningControl('manualWindDirection', 2)">
                            <div class="rm-kt-btn__icon"><span class="iconfont icon-kongtiao1"></span></div>
                            <div class="rm-kt-btn__text">中间扫风</div>
                        </div>
                    </div>
                    <div class="rm-kt-btns__item">
                        <div class="rm-kt-btn" @click="onAirConditioningControl('manualWindDirection', 3)">
                            <div class="rm-kt-btn__icon"><span class="iconfont icon-zuoyousaofeng"></span></div>
                            <div class="rm-kt-btn__text">左右扫风</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="rm-tab-panel" v-show="tabIndex==7">
                <div>
                    <div v-for="(item,index) of curtainList" :key="index" class="rm-cl-group">
                        <div class="text-center gap-mb">{{ item.alias }}</div>
                        <div class="flex-row">
                            <div class="flex-item">
                                <div class="rm-cl-btn">
                                    <div @click="sendCmd(item,1)">
                                        <div class="rm-cl-btn__icon">
                                            <span>开</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-item">
                                <div class="rm-cl-btn">
                                    <div @click="sendCmd(item,-1)">
                                        <div class="rm-cl-btn__icon">
                                            <span>停</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-item">
                                <div class="rm-cl-btn">
                                    <div @click="sendCmd(item,0)">
                                        <div class="rm-cl-btn__icon">
                                            <span>关</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <!--            <div class="rm-tab-panel" v-show="tabIndex===9">-->
            <!--                <div>-->
            <!--                    <div class="rm-ds-jiemu" @click="onTVList">-->
            <!--                        <div class="rm-ds-btn__icon"><span class="iconfont icon-jiemu"></span></div>-->
            <!--                        <div class="rm-ds-btn__text">节目</div>-->
            <!--                    </div>-->
            <!--                    <div class="rm-ds-power" @click="onTV(101)">-->
            <!--                        <div class="rm-ds-btn__icon"><span class="iconfont icon-power"></span></div>-->
            <!--                        <div class="rm-ds-btn__text">开关</div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="rm-ds-row1">-->
            <!--                    <div class="flex-column rm-ds-yidong">-->
            <!--                        <div class="flex-item flex-row flex-row-center">-->
            <!--                            <div class="rm-dstb-btn rm-dstb-btn&#45;&#45;t" @click="onTV(102)"><span-->
            <!--                                class="iconfont icon-arrow-up"></span></div>-->
            <!--                        </div>-->
            <!--                        <div class="flex-item flex-row">-->
            <!--                            <div class="flex-item rm-dslr-btn rm-dslr-btn&#45;&#45;l" @click="onTV(104)"><span-->
            <!--                                class="iconfont icon-arrow-left"></span></div>-->
            <!--                            <div class="flex-item rm-ds-play">-->
            <!--                                <div class="rm-ds-play__inner" @click="onTV(106)"><span>OK</span></div>-->
            <!--                            </div>-->
            <!--                            <div class="flex-item rm-dslr-btn rm-dslr-btn&#45;&#45;r" @click="onTV(105)"><span-->
            <!--                                class="iconfont icon-arrow-right"></span></div>-->
            <!--                        </div>-->
            <!--                        <div class="flex-item flex-row flex-row-center">-->
            <!--                            <div class="rm-dstb-btn rm-dstb-btn&#45;&#45;b" @click="onTV(103)"><span-->
            <!--                                class="iconfont icon-arrow-down"></span></div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="rm-ds-row2">-->
            <!--                    <div class="flex-item flex-row rm-ds-pingdao">-->
            <!--                        <div class="flex-item">-->
            <!--                            <div class="flex-row rm-ds-capsule">-->
            <!--                                <div class="flex-item rm-ds-capsule__item" @click="onTV(108)"><span-->
            <!--                                    class="iconfont icon-jian"></span></div>-->
            <!--                                <div><span>频道</span></div>-->
            <!--                                <div class="flex-item rm-ds-capsule__item" @click="onTV(107)"><span-->
            <!--                                    class="iconfont icon-jia"></span></div>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                        <div class="flex-item">-->
            <!--                            <div class="flex-row rm-ds-capsule">-->
            <!--                                <div class="flex-item rm-ds-capsule__item" @click="onTV(110)"><span-->
            <!--                                    class="iconfont icon-jian"></span></div>-->
            <!--                                <div><span>音量</span></div>-->
            <!--                                <div class="flex-item rm-ds-capsule__item" @click="onTV(109)"><span-->
            <!--                                    class="iconfont icon-jia"></span></div>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="rm-ds-row3">-->
            <!--                    <div class="flex-item flex-row rm-ds-btns">-->
            <!--                        <div class="flex-item">-->
            <!--                            <div @click="onTV(111)">-->
            <!--                                <div class="rm-ds-btn__icon"><span class="iconfont icon-zhuye"></span></div>-->
            <!--                                <div class="rm-ds-btn__text">主页</div>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                        <div class="flex-item">-->
            <!--                            <div @click="onTV(112)">-->
            <!--                                <div class="rm-ds-btn__icon"><span class="iconfont icon-caidan"></span></div>-->
            <!--                                <div class="rm-ds-btn__text">菜单</div>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                        <div class="flex-item">-->
            <!--                            <div @click="onTV(113)">-->
            <!--                                <div class="rm-ds-btn__icon"><span class="iconfont icon-jingyin"></span></div>-->
            <!--                                <div class="rm-ds-btn__text">静音</div>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                        <div class="flex-item">-->
            <!--                            <div @click="onTV(114)">-->
            <!--                                <div class="rm-ds-btn__icon"><span class="iconfont icon-fanhui"></span></div>-->
            <!--                                <div class="rm-ds-btn__text">返回</div>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div v-if="tv.show" class="page-dialog">-->
            <!--                    <div class="page-head">-->
            <!--                        <div class="page-head-title"><span>节目列表</span></div>-->
            <!--                        <div class="page-head-close" @click="closeTVList">-->
            <!--                            <div class="rotate-45"><span class="iconfont icon-increase"></span></div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <div class="page-body"-->
            <!--                         v-infinite-scroll="loadTVList"-->
            <!--                         :infinite-scroll-disabled="tv.loading"-->
            <!--                         :infinite-scroll-distance="15"-->
            <!--                    >-->
            <!--                        <div class="tv-list-item" v-for="(item, index) of tv.list" :key="index" @click="onTVPlay(item)">-->
            <!--                            <div class="tv-list-item__image">-->

            <!--                            </div>-->
            <!--                            <div class="tv-list-item__body">-->
            <!--                                <div class="tv-list-item__title">-->
            <!--                                    <div>{{ item.title }}</div>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->

            <div class="rm-tab-panel" v-show="tabIndex===10">
                <div v-if="!scenarioList.length" class="empty-data"><span>暂无内容</span></div>
                <div v-else class="scene-items">
                    <van-row type="flex" gutter="16" class="flex-wrap">
                        <van-col span="12" v-for="(item, index) of scenarioList" :key="index">
                            <div class="scene-item">
                                <span @click="openScene(item.id)">{{ item.alias }}</span>
                            </div>
                        </van-col>
                    </van-row>
                </div>
            </div>

            <div class="rm-tab-panel" v-if="tabIndex===14" style="display: block">
                <div class="rm-lamp-list">
                    <div class="rm-lamp-item" v-for="(item, index) of switchList" :key="index">
                        <div>
                            <div class="rm-lamp-switch" :class="{'is-active':item.value=='1'}" @click="sendCmd(item,5)">
                                <div class="rm-lamp-switch__inner">
                                    <div>
                                        <span v-if="item.loading" class="iconfont"><van-loading></van-loading></span>
                                        <span v-else class="iconfont icon-icon-power"
                                              :class="[item.value=='1'?'icon-power':'icon-power']"></span>
                                    </div>
                                </div>
                                <div class="rm-lamp-switch__label">{{ item.alias }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rm-tab-foot flex-row flex-center">
            <div>
                <span>温度：{{ temperatureC }}℃ / {{ temperatureF }}℉</span>
                <span>湿度：{{ humidity }}%</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RoomControl",
    data() {
        return {
            loading: false,
            temperatureC: 0,
            temperatureF: 0,
            humidity: 0,
            tabIndex: 13,
            tabNav: [{
                id: 13,
                title: '灯光',
                subTitle: 'Light',
                disabled: true,
                deviceId: '',
                icon: 'icon-deng',
                children: []
            }, {
                id: 8,
                title: '空调',
                subTitle: 'Air conditioner',
                disabled: true,
                deviceId: '',
                icon: 'icon-kongtiao1',
                children: []
            }, {
                id: 7,
                title: '窗帘',
                subTitle: 'Curtain',
                disabled: true,
                deviceId: '',
                icon: 'icon-chuangliankai',
                children: []
                // }, {
                //     id: 9,
                //     title: '电视',
                //     subTitle: 'Television',
                //     disabled: true,
                //     deviceId: '',
                //     icon: 'icon-dianshi',
                //     children: []
            }, {
                id: 10,
                title: '情景模式',
                subTitle: 'Sound',
                disabled: true,
                deviceId: '',
                icon: 'icon-scenario',
                children: []
            }, {
                id: 14,
                title: '开关',
                subTitle: 'switch',
                disabled: true,
                deviceId: '',
                icon: 'icon-power',
                children: []
            }],
            instructSleep: false,
            tv: {
                show: false,
                page: 0,
                limit: 20,
                loaded: false,
                loading: false,
                list: []
            },
            tvId: '', //电视ID

            lampList: [], //灯
            airConditioner: { // 空调
                enabled: false,
                id: '',
                temperature: 27,
                min: 16,
                max: 30
            },
            curtainList: [], //窗帘
            television: { //电视
                enabled: false,
                id: ''
            },
            scenarioList: [], //情景模式
            switchList: [], //开关
        }
    },
    computed: {
        hotelId() {
            return this.$route.query.hotelId
        },
        roomId() {
            return this.$route.query.roomId
        }
    },
    methods: {
        toggleTab: function (data) {
            this.tabIndex = data.id;
            this.getDevices()
        },
        // 获取设备列表
        getDevices() {
            if (this.loading) return false
            this.loading = true
            this.$axios.get('/api/weChatMini/v1/smart/smartDevice', {
                params: {
                    hotelBranchId: this.hotelId,
                    hotelRoomId: this.roomId
                }
            }).then(res => {
                this.loading = false
                this.deviceGroup(res.list)
            }).catch(err => {
                this.loading = false
                this.$dialog.alert({
                    message: err.message
                })
            })
        },
        // 设备分组
        deviceGroup(data) {
            let lightArr = [],
                airConditioningArr = [],
                curtainArr = [],
                tvArr = [],
                sceneArr = [],
                switchArr = [];

            for (let item of data) {
                if (item.type === '网关') {
                    for (let elem of item.children) {
                        elem.gatewayId = item.id
                        elem.gatewayAlias = item.alias
                        switch (elem.type) {
                            case '灯':
                                lightArr.push(elem);
                                break;
                            case '红外控制设备':
                                if (elem.alias.indexOf('空调') >= 0) {
                                    airConditioningArr.push(elem);
                                }
                                if (elem.alias.indexOf('电视') >= 0) {
                                    tvArr.push(elem);
                                }
                                break;
                            case '窗帘':
                                curtainArr.push(elem);
                                break;
                            case '电视机顶盒':
                                tvArr.push(elem);
                                break;
                            case '情景开关':
                                sceneArr.push(elem);
                                break;
                            case '单路开关':
                                switchArr.push(elem);
                                break;
                            case '多路开关':
                                switchArr.push(elem);
                                break;
                            case '温湿度传感器':
                                // eslint-disable-next-line no-case-declarations
                                let arr = elem.value.split(',')
                                this.temperatureC = Number(arr[0])
                                this.temperatureF = this.convertTemperature(arr[0])
                                this.humidity = arr[1]
                                break;
                            case '红外转发器':

                                break;
                        }
                    }
                } else {
                    item.gatewayId = item.id
                    item.gatewayAlias = item.alias
                    switch (item.type) {
                        case '灯':
                            lightArr.push(item);
                            break;
                        case '红外控制设备':
                            if (item.alias.indexOf('空调') >= 0) {
                                airConditioningArr.push(item);
                            }
                            if (item.alias.indexOf('电视') >= 0) {
                                tvArr.push(item);
                            }
                            break;
                        case '窗帘':
                            curtainArr.push(item);
                            break;
                        case '电视':
                            tvArr.push(item);
                            break;
                        case '情景开关':
                            sceneArr.push(item);
                            break;
                        case '单路开关':
                            switchArr.push(item);
                            break;
                        case '多路开关':
                            switchArr.push(item);
                            break;
                        case '温湿度传感器':
                            // eslint-disable-next-line no-case-declarations
                            let arr = item.value.split(',')
                            this.temperatureC = Number(arr[0])
                            this.temperatureF = this.convertTemperature(arr[0])
                            this.humidity = arr[1]
                            break;
                    }
                }
            }

            this.lampList = lightArr
            if (airConditioningArr.length) {
                this.airConditioner = airConditioningArr[0]
                this.airConditioner.enabled = true
                this.airConditioner.powerStatus = '关'
            }
            this.curtainList = curtainArr
            if (tvArr.length) {
                this.television = tvArr[0]
                this.television.enabled = true
            }
            this.switchList = switchArr
        },
        // 发送控制指令
        sendCmd: function (data, num) {
            let value = data.value === '0' ? '1' : '0';
            if (num != 5) {
                value = num;
            }

            if (data.loading) return;
            data.loading = true;
            this.$axios.get('/api/weChatMini/v1/smart/controlSmart', {
                params: {
                    controlValue: value,
                    deviceId: data.id,
                    gatewayId: data.gatewayId
                }
            }).then(res => {
                data.loading = false
                data.value = value
            }).catch(err => {
                data.loading = false
                this.$dialog.alert({
                    message: err.message
                })
            })
        },
        // 转换为华氏度
        convertTemperature(value) {
            if (!value) return 0;
            let val = Number(value);
            if (isNaN(val)) return 0;
            val = (val * 9 / 5) + 32;
            return parseInt(val);
        },
        // 打开情情模式
        openScene(id) {
            this.$axios.get('/api/weChatMini/v1/smart/openSmartScene', {
                params: {
                    sceneId: id,
                    roomId: this.roomId
                }
            }).then(res => {

            })
        },
        // 获取情景模式列表
        getScene() {
            this.$axios.get('/api/weChatMini/v1/smart/smartScene', {
                params: {
                    hotelBranchId: this.hotelId,
                    hotelRoomId: this.roomId
                }
            }).then(res => {
                this.scenarioList = res.entity.sceneList
            })
        },
        // 空调控制
        onAirConditioningControl(key, value) {
            if (!this.airConditioner.enabled) {
                this.$dialog.alert({
                    message: '没有找到设备'
                })
                return false
            }
            let device = this.airConditioner
            let form = {
                "deviceId": device.id,
                "expiration": 0,
                "gatewayId": device.gatewayId,
                "manualWindDirection": "",
                "mode": "",
                "powerSwitch": "",
                "temperature": "",
                "windDirection": "",
                "windSpeed": ""
            }
            if(key === 'temperature') {
                let _val = this.airConditioner.temperature + value
                if(_val > 30) _val = 30
                if(_val < 16) _val = 16
                this.airConditioner.temperature = _val
                form[key] = _val
            } else {
                form[key] = value
            }

            this.$axios.post('/api/weChatMini/v1/smart/controlSmartAir', form).then(res => {

            }).catch(err => {
                this.$dialog.alert({
                    message: err.message
                })
            })
        },
        // 打开电视节目列表
        onTVList: function () {
            this.tv.show = true;
            this.tv.page = 0;
            this.tv.loaded = false;
            this.tv.list = [];
            //this.loadTVList()
        },
        closeTVList: function () {
            this.tv.show = false;
        },
        // 加载电视节目列表
        loadTVList() {
            if (this.tv.loading) {
                return false
            }

            // var self = this;
            // self.tv.loading = true;
            // self.tv.page++;
            // $.ajax({
            //     method: 'GET',
            //     url: this.$store.state.apiUrl,
            //     headers: {
            //         'token': self.loginInfo.openid
            //     },
            //     data: {
            //         action: 'tvList',
            //         loupanId: this.$route.query.hotelId,
            //         page: self.tv.page,
            //         limit: self.tv.limit,
            //         _t: (new Date()).getTime()
            //     },
            //     dataType: 'json',
            //     success: function(res) {
            //         self.tv.loading = false;
            //         if(res.code == 0) {
            //             if(self.tv.page == 1) {
            //                 self.tv.list = res.data;
            //             } else {
            //                 self.tv.list = self.tv.list.concat(res.data);
            //             }
            //         } else {
            //             MINT.MessageBox.alert(res.msg);
            //         }
            //     },
            //     error: function(xhr) {
            //         self.tv.loading = false;
            //         MINT.MessageBox.alert(xhr.status+','+xhr.statusText);
            //     }
            // })
        },
        // 发送电视控制指令
        sendInstruct(data) {
            if (this.instructSleep) {
                return false;
            }
            this.instructSleep = true;
            this.$axios.post('/api/weChatMini/v1/smart/controlSmartAir', {
                "deviceId": "",
                "expiration": 0,
                "gatewayId": "",
                "manualWindDirection": "",
                "mode": "",
                "powerSwitch": "",
                "temperature": "",
                "windDirection": "",
                "windSpeed": ""
            }).then(res => {

            }).finally(() => {
                // 防止指令发送过快
                setTimeout(() => {
                    this.instructSleep = false;
                }, 800);
            })
        },
    },
    mounted() {
        this.getDevices()
        this.getScene()
    }
}
</script>

<style lang="scss" scoped>
.rm-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
}

.rm-tab-nav {
    height: 0.86rem;
    flex: 0 0 0.86rem;
    min-height: 0;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    padding: 0 0.08rem;
    position: relative;
    z-index: 8;
}

.rm-tab-nav__item {
    -webkit-box-flex: 1;
    flex: 1;
    min-width: 0;
    color: #9fa0a0;
    box-sizing: border-box;
}

.rm-tab-nav__inner {
    border: 1px solid transparent;
    box-sizing: border-box;
    font-size: 0.30rem;
    height: 1.8em;
    line-height: 1;
    border-radius: 0.06rem;
    text-align: center;
}

.rm-tab-nav__inner:active {
    border-color: #ddd;
}

.rm-tab-nav__label {
    font-size: 0.11rem;
    padding-top: 0.04rem;
}

.rm-tab-nav__item.is-active {
    color: #c6a03b;
}

.rm-tab-nav__item.is-active .rm-tab-nav__inner {
    border-color: currentColor;
}

.rm-tab-nav__item:active {
    background: none;
}

.rm-tab-body {
    flex: 1;
}
.loading-mask {
    position: absolute;
    z-index: 9;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
    display: none;
    justify-content: center;
    align-items: center;
}
.loading-body .loading-mask {
    display: flex;
}

.rm-tab-panel {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.rm-tab-foot {
    background-color: #fff;
    height: 0.44rem;
    flex: 0 0 0.44rem;
    min-height: 0;
}

.rm-tab-foot span {
    margin-left: 2em;
}

.rm-tab-foot span:first-child {
    margin-left: 0;
}

.rm-lamp-brightness {
    padding: 0.35rem 0 0.1rem;
}


.rm-lm-group {
    display: -webkit-flex;
    display: flex;
    width: 2rem;
    height: 0.45rem;
    border-radius: 0.23rem;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 1px 0.16rem rgba(0, 0, 0, 0.06);
}

.rm-lm-group-btn {
    -webkit-box-flex: 1;
    flex: 1;
    text-align: center;
    line-height: 0.45rem;
    font-size: 0.16rem;
}

.rm-lm-group.is-close .rm-lm-group-btn:first-child {
    background-color: #c6a03b;
    color: #fff;
}

.rm-lm-group.is-open .rm-lm-group-btn:last-child {
    background-color: #c6a03b;
    color: #fff;
}

.rm-lamp-master {
    flex: 0 0 0.8rem;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rm-lamp-list {
    flex: 1;
    min-height: 0;
    padding: 0.12rem 0.06rem ;
    display: flex;
    flex-wrap: wrap;
}

.rm-lamp-item {
    flex: 0 0 20%;
    margin-bottom: 0.12rem;
}

.rm-lamp-switch {
    margin: 0 auto;
    text-align: center;
}

.rm-lamp-switch__inner {
    width: 0.48rem;
    height: 0.48rem;
    line-height: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0.2rem rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    color: #a5a6a6;
}

.rm-lamp-switch__inner span {
    display: block;
}

.rm-lamp-switch__inner .iconfont {
    font-size: 0.22rem;
}

.rm-lamp-switch__label {
    font-size: 0.11rem;
    margin-top: 0.08rem;
}

.rm-lamp-switch.is-active .rm-lamp-switch__inner {
    color: #c6a03b;
    box-shadow: inset 0 0 0.2rem currentColor;
}

.rm-lamp-switch__inner:active {
    background-color: #c6a03b;
    color: #fff !important;
}

.brightness {
    opacity: 0.4;
}

.brightness.is-enabled {
    opacity: 1;
}

.rm-ls-brightness {
    display: inline-block;
    color: #a5a6a6;
    font-size: 0.12rem;
    border: 1px solid currentColor;
    position: relative;
    line-height: 1.5;
    height: 1.5em;
    padding-left: 1.7em;
    padding-right: 0.2em;
    margin-top: 0.1rem;
    border-radius: 0.03rem;
}

.rm-ls-brightness .iconfont {
    background-color: #a5a6a6;
    color: #fff;
    display: block;
    position: absolute;
    height: 1.5em;
    width: 1.5em;
    left: -1px;
    top: -1px;
    text-align: center;
    border-radius: 0.04rem;
}

.rm-ls-brightness.is-active {
    color: #c6a03b;
}

.rm-ls-brightness.is-active .iconfont {
    background-color: #c6a03b;
    color: #fff;
}

/* 空调 */
.rm-kt-temperature {
    padding: 0 0.14rem;
}

.rm-kt-temperature-tip {
    color: #9fa0a0;
    font-size: 0.18rem;
    text-align: center;
}

.rm-kt-temperature-number {
    font-size: 0.7rem;
    color: #c6a03b;
}

.rm-kt-temperature-number span {
    font-size: 0.32rem;
}

.rm-kt-temperature-increase,
.rm-kt-temperature-decrease {
    -webkit-box-flex: 1;
    flex: 1;
    position: relative;
}

.rm-kt-temperature-increase::before,
.rm-kt-temperature-decrease::before {
    content: "";
    position: absolute;
    height: 1px;
    background-color: #c6a03b;
    top: 50%;
}

.rm-kt-temperature-increase::before {
    left: 0.5rem;
    right: 0.1rem;
}

.rm-kt-temperature-decrease::before {
    left: 0.1rem;
    right: 0.5rem;
}

.rm-kt-temperature-increase span,
.rm-kt-temperature-decrease span {
    display: block;
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    position: relative;
    color: #c8c8c8;
    border: 2px solid currentColor;
}

.rm-kt-temperature-increase span:active,
.rm-kt-temperature-decrease span:active {
    color: #c6a03b;
}

.rm-kt-temperature-increase span::before {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    background-color: currentColor;
    left: 10%;
    top: 50%;
    margin-top: -1px;
}

.rm-kt-temperature-decrease span::before {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    background-color: currentColor;
    left: 10%;
    top: 50%;
    margin-top: -1px;
}

.rm-kt-temperature-decrease span::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 80%;
    background-color: currentColor;
    left: 50%;
    top: 10%;
    margin-left: -1px;
}

.rm-kt-temperature-decrease span {
    margin: 0 0 0 auto;
}

.rm-fengsu {
    padding: 0.14rem;
    flex: 0 0 1.1rem;
}

.rm-fengsu-label {
    color: #c6a03b;
    font-size: 0.14rem;
    margin-bottom: 0.15rem;
}

.rm-fengsu-label .iconfont {
    font-size: 1.2em;
}

.rm-fengsu-bar {
    height: 0.08rem;
    background-color: #e9e9e9;
    border-radius: 0.04rem;
}

.rm-fengsu-bar > div {
    display: -webkit-flex;
    display: flex;
    justify-content: center;
}

.rm-fengsu-bar__dotted {
    width: 0.08rem;
    height: 0.08rem;
    position: relative;
    margin: 0 auto;
}

.rm-fengsu-bar__dotted::before {
    content: "";
    display: block;
    width: 0.08rem;
    height: 0.08rem;
    border-radius: 50%;
    background-color: #c6a03b;
}

.rm-fengsu-bar__dotted::after {
    visibility: hidden;
    content: "";
    position: absolute;
    width: 0.20rem;
    height: 0.20rem;
    border-radius: 50%;
    border: 0.02rem solid #c6a03b;
    left: -0.06rem;
    top: -0.06rem;
}

.rm-fengsu-bar__dotted span {
    visibility: hidden;
    position: absolute;
    left: 0.02rem;
    bottom: -0.11rem;
    width: 0.02rem;
    height: 0.06rem;
    background-color: #c6a03b;
}

.rm-fengsu-bar__label {
    font-size: 0.12rem;
    color: #9fa0a0;
    margin-top: 0.13rem;
}

.rm-fengsu-bar > div.is-active .rm-fengsu-bar__dotted::after,
.rm-fengsu-bar > div.is-active .rm-fengsu-bar__dotted span {
    visibility: visible;
}

.rm-fengsu-bar > div:active .rm-fengsu-bar__dotted::after,
.rm-fengsu-bar > div:active .rm-fengsu-bar__dotted span {
    visibility: visible;
    background-color: #c6a03b;
}

.rm-kt-btns {
    padding: 0.07rem 0.14rem 0.07rem;
}

.rm-kt-btns__item {
    flex: 0 0 25%;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    padding: 0.07rem 0;
}

.rm-kt-btn__icon {
    width: 0.5rem;
    height: 0.5rem;
    border: 2px solid #ddd;
    border-radius: 0.25rem;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.28rem;
    color: #999;
    margin-bottom: 0.05rem;
}

.rm-kt-btn__text {
    text-align: center;
    font-size: 0.14rem;
    color: #9fa0a0;
}

.rm-kt-btn:active .rm-kt-btn__icon {
    box-shadow: 0 0 0.15rem rgba(0, 0, 0, 0.15);
}

.rm-kt-btn.is-active .rm-kt-btn__icon {
    background-color: #c6a03b;
    border-color: #c6a03b;
    color: #fff;
}

/* 窗帘 */
.rm-cl-group {
    background-color: #fff;
    padding: 0.16rem 0;
    border-bottom: 1px solid #ddd;
}

.rm-cl-group + .rm-cl-group {
    margin-top: 0.16rem;
}

.rm-cl-btn {
    margin: 0 auto;
    width: 0.5rem;
    color: #9fa0a0;
}

.rm-cl-btn__icon {
    width: 0.5rem;
    height: 0.5rem;
    line-height: 1.2;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    font-size: 0.16rem;
}

.rm-cl-btn:active .rm-cl-btn__icon {
    box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.15);
}

.rm-cl-btn.is-active {
    color: #c6a03b;
}

.rm-cl-btn.is-active .rm-cl-btn__icon {
    background-color: #c6a03b;
    color: #fff;
}

/* 音响 */
.rm-yx-btns {
    width: 2.89rem;
    height: 2.89rem;
    background-color: #272424;
    box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    color: #9d9e9e;
    line-height: 1;
}

.rm-yxtb-btn {
    font-size: 0.52rem;
    flex: 0 0 33.333333%;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    padding: 0.1rem;
}

.rm-yxtb-btn--t {
    align-items: flex-start;
}

.rm-yxtb-btn--b {
    align-items: flex-end;
}

.rm-yxtb-btn:active {
    color: #c6a03b;
}

.rm-yxlr-btn {
    font-size: 0.36rem;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}

.rm-yxlr-btn--l {
    justify-content: flex-start;
    padding-left: 0.15rem;
}

.rm-yxlr-btn--r {
    justify-content: flex-end;
    padding-right: 0.15rem;
}

.rm-yxlr-btn:active {
    color: #c6a03b;
}

.rm-yx-play {
    background-color: #fff;
    padding: 0.12rem;
    border-radius: 50%;
}

.rm-yx-play__inner {
    background-color: #c6a03b;
    color: #fff;
    font-size: 0.4rem;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.rm-yx-play__inner:active {
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.3);
}

/* 电视 */
.rm-ds-yidong {
    width: 2rem;
    height: 2rem;
    background-color: #fff;
    box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    color: #9d9e9e;
    line-height: 1;
    font-size: 0.32rem;
}

.rm-dstb-btn {
    flex: 0 0 33.333333%;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    padding: 0.1rem;
}

.rm-dstb-btn--t {
    align-items: flex-start;
}

.rm-dstb-btn--b {
    align-items: flex-end;
}

.rm-dstb-btn:active {
    color: #c6a03b;
}

.rm-dslr-btn {
    flex: 0 0 33.333333%;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}

.rm-dslr-btn--l {
    justify-content: flex-start;
    padding-left: 0.15rem;
}

.rm-dslr-btn--r {
    justify-content: flex-end;
    padding-right: 0.15rem;
}

.rm-dslr-btn:active {
    color: #c6a03b;
}

.rm-ds-play {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.13);
    position: relative;
}

.rm-ds-play__inner {
    background-color: #c6a03b;
    color: #fff;
    font-size: 0.2rem;
    position: absolute;
    left: 15%;
    top: 15%;
    width: 70%;
    height: 70%;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.rm-ds-play__inner:active {
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.3);
}

.rm-ds-capsule {
    height: 0.46rem;
    line-height: 0.44rem;
    border: 1px solid #ddd;
    border-radius: 0.23rem;
    color: #999;
    background-color: #fff;
    font-size: 0.16rem;
}

.rm-ds-capsule__item {
    text-align: center;
}

.rm-ds-capsule__item:first-child {
    border-top-left-radius: 0.23rem;
    border-bottom-left-radius: 0.23rem;
}

.rm-ds-capsule__item:first-child:active {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}

.rm-ds-capsule__item:last-child {
    border-top-right-radius: 0.23rem;
    border-bottom-right-radius: 0.23rem;
}

.rm-ds-capsule__item:last-child:active {
    background: linear-gradient(-90deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}

.rm-ds-pingdao > div {
    padding: 0 0.14rem;
}

.rm-ds-btn__icon {
    width: 0.52rem;
    height: 0.52rem;
    border: 1px solid #ddd;
    border-radius: 50%;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
    background-color: #fff;
    font-size: 0.26rem;
}

.rm-ds-btn__icon:active {
    background-color: #c6a03b;
    border-color: #c6a03b;
    color: #fff;
}

.rm-ds-btn__text {
    text-align: center;
    font-size: 0.14rem;
    color: #666;
}

.rm-ds-row1,
.rm-ds-row2,
.rm-ds-row3 {
    display: -webkit-flex;
    display: flex;
}

.rm-ds-row1 {
    justify-content: center;
    -webkit-box-flex: 3;
    flex: 3;
    align-items: center;
}

.rm-ds-row2 {
    -webkit-box-flex: 1;
    flex: 1;
}

.rm-ds-row3 {
    -webkit-box-flex: 1;
    flex: 1;
}

.scene-items {
    padding: 16px;
}

.scene-item {
    height: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 16px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);

    &:active {
        opacity: 0.6;
    }
}
</style>